<template>
    <validation-observer ref="form" v-slot="{ invalid }">
        <v-form @submit.prevent="submit"  class="mb-16"  v-if="!loading">
            <v-container>
                <v-card class="elevation-5">
                    <v-card-title class="primary white--text">
                        {{$t('complaints.case_detail')}}
                        <v-container>
                            <v-speed-dial
                            absolute
                            v-model="speedDial"
                            top
                            right
                            direction="bottom"
                            :open-on-hover="true"
                            >
                            <template v-slot:activator>
                                <v-tooltip right color="primary">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        v-bind="attrs" v-on="on"
                                        v-model="speedDial"
                                        class="elevation-0"
                                        color="primary"
                                        x-large
                                        >
                                        <v-icon v-if="speedDial">
                                            mdi-close
                                        </v-icon>
                                        <v-icon v-else>
                                            mdi-dots-vertical
                                        </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('globals.options')}}</span>
                                    </v-tooltip>
                                </template>
                                <v-tooltip right color="success">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs" v-on="on"
                                        fab
                                        dark
                                        small
                                        color="success"
                                        large
                                        @click="exportComplaint()"
                                    >
                                        <v-icon>mdi-export</v-icon>
                                    </v-btn>
                                    </template>
                                    <span>{{$t('globals.export')}} Excel</span>
                                </v-tooltip>
                                <v-tooltip right color="error">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs" v-on="on"
                                        fab
                                        dark
                                        small
                                        color="error"
                                        large
                                        @click="exportComplaint('PDF')"
                                    >
                                        <v-icon>mdi-file-pdf-box</v-icon>
                                    </v-btn>
                                    </template>
                                    <span>{{$t('globals.export')}} PDF</span>
                                </v-tooltip>
                            </v-speed-dial>
                        </v-container>
                    </v-card-title>
                    <v-card-text v-if="!loading" class="mt-4">
                        <v-row>
                            <v-col cols="12" md="4">
                                {{$t('globals.reference')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                {{fields.reference}}
                            </v-col>

                            <v-col cols="12" md="4">
                                {{$t('globals.date')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                {{fields.created_at}}
                            </v-col>
                            
                            <v-col cols="12" md="4">
                                {{$t('globals.title')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                {{fields.title}}
                            </v-col>
                            
                            <v-col cols="12" md="4">
                                {{$t('globals.description')}}
                            </v-col>
                            
                            <v-col cols="12" md="8">
                                <template v-if="audioUrl">
                                    <v-row justify="center" align="center" class="mb-3">
                           
                                        <v-col cols="12" class="d-flex">
                                            <audio :src="audioUrl" controls></audio>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-else>
                                    {{fields.description}}
                                </template>
                            </v-col>
                            
                            <v-col cols="12" md="4">
                                {{$t('globals.associate')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-select
                                    v-model="fields.relationship"
                                    :items="relations"
                                    :item-text="item => item.reference"
                                    item-value="id"
                                    menu-props="auto"
                                    :label="$t('globals.complaints')"
                                    prepend-inner-icon="mdi-account"
                                    clearable
                                ></v-select>  
                            </v-col>
                            
                            <v-col cols="12" md="4">
                                {{$t('globals.category')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-select
                                    v-model="fields.category_id"
                                    :items="categories"
                                    :item-text="item => item.i18n[$i18n.locale]"
                                    item-value="id"
                                    menu-props="auto"
                                    label="Categoria"
                                    prepend-inner-icon="mdi-account"
                                    clearable
                                ></v-select>  
                            </v-col>
                            
                            <v-col cols="12" md="4">
                                {{$t('globals.status')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-select
                                    v-model="fields.state_id"
                                    :items="states"
                                    :item-text="item => item.i18n[$i18n.locale]"
                                    item-value="id"
                                    menu-props="auto"
                                    label="Estado"
                                    prepend-inner-icon="mdi-account"
                                    clearable
                                ></v-select>  
                            </v-col>
                            
                            <v-col cols="12" md="4">
                                {{$t('globals.department')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-select
                                    v-model="fields.department_id"
                                    :items="departments"
                                    :item-text="item => item.i18n[$i18n.locale]"
                                    item-value="id"
                                    menu-props="auto"
                                    :label="$t('globals.category')"
                                    prepend-inner-icon="mdi-account"
                                    clearable
                                ></v-select>  
                            </v-col>
                            
                            
                            <v-col cols="12" md="4">
                                {{$t('globals.severity')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-select
                                    v-model="fields.severity_id"
                                    :items="severities"
                                    :item-text="item => item.level"
                                    item-value="id"
                                    menu-props="auto"
                                    label="Severity"
                                    prepend-inner-icon="mdi-account"
                                    clearable
                                ></v-select>  
                            </v-col>
                            
                            
                            <v-col cols="12" md="4">
                                {{$t('globals.responsibles')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-select
                                    v-model="fields.users"
                                    :items="users"
                                    :label="$t('globals.responsibles')"
                                    :item-text="item => item.name"
                                    :item-value="item => item.id"
                                    multiple
                                    chips
                                    persistent-hint
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="fields.confidential">
                            <v-col cols="12" md="4">
                                {{$t('globals.name')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                {{fields.person.name}}
                            </v-col>
                            <v-col cols="12" md="4">
                                {{$t('globals.email')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                {{fields.person.email}}
                            </v-col>
                            <v-col cols="12" md="4">
                                {{$t('globals.contact')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                {{fields.person.telephone}}
                            </v-col>
                        </v-row>
                        <v-row>                    
                            <v-col cols="12" md="4">
                                {{$t('globals.files')}} 
                            </v-col>
                        </v-row>
                        <v-row v-for="item in fields.files" :key="item">
                            <v-col cols="12" md="4">
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-btn @click="downloadFile(item)" icon class="success">
                                    <v-icon color="white">mdi-download-box</v-icon>
                                </v-btn>
                                {{item}}
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>                        
                        <v-row class="mt-6 mb-5" align="center" justify="space-around">
                            <v-btn :disabled="invalid" depressed color="primary" type="submit">
                                {{$t('globals.save')}}
                            </v-btn>
                        </v-row>
                    </v-card-actions>
                    <v-expansion-panels>
                        <v-expansion-panel
                        >
                        <v-expansion-panel-header class="primary white--text text-h6 mt-4 elevation-4">
                            <template v-slot:actions>
                                <v-icon color="white">
                                $expand
                                </v-icon>
                            </template>
                            {{$t('globals.comments')}} ({{complaint.comments.length}})
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="elevation-4">
                            <v-card-text>
                                <v-row v-for="comment in complaint.comments" :key="comment.id">
                                    <v-col cols="12" md="1">
                                        <v-avatar
                                            v-if="comment.user == null"
                                            class="orange darken-2 white--text text-h6"
                                            size="56"
                                        >
                                            WB
                                        </v-avatar>
                                        <v-avatar
                                            v-else
                                            class="primary white--text text-h6"
                                            size="56"
                                        >
                                            {{comment.user.initials}}
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="12" md="11" class="mt-4">
                                        {{comment.message}}
                                    </v-col>   
                                    <v-col cols="12" style="position:relative;" class="mt-n3">   
                                        <span class="text-caption" style="bottom: 0; right: 0; position: absolute;">
                                            {{comment.user == null ? (complaint.anonymous ? 'Anonymous' : complaint.person.name) : comment.user.name}} {{$t('globals.at')}} {{comment.created_at}}
                                            <div v-if="comment.internal == false && comment.user_id != null">
                                                <br/>
                                                <strong>{{comment.seen ? $t('globals.saw_whistleblower') : $t('globals.not_seen')}}</strong>
                                            </div>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" md="12" v-if="comment.files.length > 0">
                                        <v-expansion-panels>
                                            <v-expansion-panel>
                                                <v-expansion-panel-header class="primary white--text">
                                                    <template v-slot:actions>
                                                        <v-icon class="icon" color="white">$expand</v-icon>
                                                    </template>
                                                    {{$t('globals.documents')}} ({{comment.files.length}})
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-col cols="12" md="12" v-for="f in comment.files" :key="f">
                                                        <v-btn @click="downloadCommentFile(f, comment.id)" icon class="success mr-2">
                                                            <v-icon color="white">mdi-download-box</v-icon>
                                                        </v-btn>
                                                        <span class="text-caption">{{f}}</span>
                                                    </v-col>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-col>                           
                                    <v-col cols="12">                                
                                        <v-divider></v-divider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea 
                                            filled
                                            :label="$t('globals.insert_comment')"
                                            v-model="comment.message"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-file-input
                                            multiple
                                            v-model="comment.files"
                                            :label="$t('globals.attach_files')"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="auto">
                                        <v-switch
                                            v-model="comment.internal"
                                            :label="$t('globals.internal_note')"
                                            color="error"
                                            inset
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-btn @click="initiateAddComment">{{$t('globals.comment')}}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    
                </v-card>
            </v-container>
            <ChangeState v-if="changeState" :states="states" :actualState="fields.state_id" @add-comment="addComment"/>
            <DialogSuccess :opened="success" @on-ok="onSuccess" >
                {{$t('globals.success_action')}}
            </DialogSuccess>
        </v-form>
    </validation-observer> 
</template>
<script>
import States from '@/api/State.js'
import User from '@/api/User.js'
import Category from '@/api/Category.js'
import Department from '@/api/Department.js'
import Severities from '@/api/Severities.js'
import Complaints from '@/api/Complaints.js'
import Comment from '@/api/Comment.js'
import DialogSuccess from '@/components/ui/DialogSuccess'
import ChangeState from '@/components/complaints/dialogs/ChangeState'

export default{
    props:{
        complaint: Object,
        audioUrl: String
    },
    components:{
        DialogSuccess,
        ChangeState
    },
    data(){
        return{
            changeState: false,
            speedDial: false,
            success: false,
            loading: true,
            states: [],
            categories: [],
            departments: [],
            severities: [],
            relations: [],
            users: [],
            fields: null,
            comment: {
                message: null,
                files: null,
                seen: false,
                user_id: null,
                complaint_id: null,
                internal: false
            }
        }
    },
    mounted(){
        this.fields = this.complaint
        this.fillBaseData();
    },
    methods:{
        exportComplaint(type = 'Excel'){
            let data = {
                locale: this.$i18n.locale,
                type: type,
            }
            
            Complaints.export(this.fields.id, data).then((resp) => {
                if(resp.data != null){
                    window.open(resp.data, '_blank')
                }
            });
        },
        onSuccess(){
            this.$emit('reload')
        },
        initiateAddComment(){
            this.changeState = true;
        },
        addComment(state_id){
            this.comment.user_id = this.$root.session.id
            this.comment.complaint_id = this.$route.params.id

            const formData = new FormData();
            formData.append('state_id', state_id);
            formData.append('message', this.comment.message)
            formData.append('user_id', this.comment.user_id)
            formData.append('internal', this.comment.internal)
            formData.append('complaint_id', this.comment.complaint_id)
            if(this.comment.files != null){
                for (var i = 0; i < this.comment.files.length; i++ ){
                    let file = this.comment.files[i];
                    formData.append('files[]', file);
                }
            }

            Comment['create'](formData).then(() => {
                this.success = true
                this.loading = false
            }).catch(err => {
                    this.loading = false;
        
                    if(err.response.status == 422) {
        
                        this.$refs.form.setErrors(err.response.data.errors);
                        
                        return;
                    }
        
                    this.error.title = "Erro " + err.response.status;
        
                    this.error.message = err.response.data.message;
        
            });
        },
        submit(){
            this.$refs.form.validate().then((result) => {
                this.loading = true;
                
                if(!result){
                    this.loading = false;
                    return;
                }
        
                let data = {...this.fields};
        
                Complaints['update'](data, this.fields.id).then(() => {
        
                    this.success = true;
        
                    this.loading = false;
                    
                }).catch(err => {
                    this.loading = false;
        
                    if(err.response.status == 422) {
        
                        this.$refs.form.setErrors(err.response.data.errors);
                        
                        return;
                    }
        
                    this.error.title = "Erro " + err.response.status;
        
                    this.error.message = err.response.data.message;
        
                    });
                })
        },
        downloadCommentFile(file, id){
            Comment.downloadFile(file, id).then((response) => {
                let documentName =  file;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        downloadFile(file){
            Complaints.downloadFile(file, this.complaint.id).then((response) => {
                let documentName =  file;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        async fillBaseData(){
            States.list().then(({data}) => {
                this.states = data;
            });
            Category.list().then(({data}) => {
                this.categories = data;
            });
            Department.list().then(({data}) => {
                this.departments = data;
            });
            Severities.list().then(({data}) => {
                this.severities = data;
            });
            User.list().then(({data}) => {
                this.users = data.filter(item => item.permissions.some(permission => permission.name == "complaints.manager"));
            });
            Complaints.getRelations().then(({data}) => {
                this.relations = data
            });

            this.loading = false
        }
    }
}
</script>